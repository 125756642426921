<template>
  <div class="overflow-auto">
    <table class="excel">
      <thead>
        <tr>
          <th />
          <th v-for="i in Object.entries(definicion).length" :key="i">
            {{ az[i - 1] }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr class="row1">
          <th>1</th>
          <td
            v-for="(columna, campo) in definicion"
            :key="campo"
            :class="{
              requerido:
                (columna.rules && columna.rules.includes('required')) ||
                (columna.rules && columna.rules.includes('Sem\\Base\\Rules\\Requerido')),
            }"
          >
            {{ campo }}
          </td>
        </tr>
        <tr>
          <th>2</th>
          <td v-for="(columna, campo) in definicion" :key="campo">
            {{ columna.ejemplo }}
          </td>
        </tr>
        <tr v-for="fila in [3, 4, 5]" :key="fila">
          <th>{{ fila }}</th>
          <td v-for="(columna, campo) in definicion" :key="campo">...</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    tramite: {
      type: Object,
      required: true,
    },
    definicion: {
      type: Object,
    },
  },
  data: () => ({
    az: [
      'A',
      'B',
      'C',
      'D',
      'E',
      'F',
      'G',
      'H',
      'I',
      'J',
      'K',
      'L',
      'M',
      'N',
      'O',
      'P',
      'Q',
      'R',
      'S',
      'T',
      'U',
      'V',
      'W',
      'X',
      'Y',
      'Z',
      'AA',
      'AB',
      'AC',
      'AD',
      'AE',
      'AF',
      'AG',
      'AH',
      'AI',
      'AJ',
      'AK',
      'AL',
      'AM',
      'AN',
      'AO',
      'AP',
      'AQ',
      'AR',
      'AS',
      'AT',
      'AU',
      'AV',
      'AW',
      'AX',
      'AY',
      'AZ',
      'BA',
      'BB',
      'BC',
      'BD',
      'BE',
      'BF',
      'BG',
      'BH',
      'BI',
      'BJ',
      'BK',
      'BL',
      'BM',
      'BN',
      'BO',
      'BP',
      'BQ',
      'BR',
      'BS',
      'BT',
      'BU',
      'BV',
      'BW',
      'BX',
      'BY',
      'BZ',
    ],
  }),
  computed: {},
  methods: {},
}
</script>

<style scoped>
/** Tablas que simulan Excel */

table.excel {
  background-color: #fff;
  border: 1px solid #9e9e9e;
  border-radius: 2px;
  border-spacing: 0;
  border-collapse: separate;
  font-family: sans-serif;
  font-size: 11px;
}

table.excel th,
table.excel td {
  border-top: 1px solid #f8f8f8;
  border-left: 1px solid #f8f8f8;
  border-right: 1px solid #cfcfcf;
  border-bottom: 1px solid #cfcfcf;
  padding: 3px 4px 0 4px;
  white-space: nowrap;
}

table.excel tbody th,
table.excel thead th {
  background-color: #eceff1;
  color: #000;
  font-weight: normal;
  text-align: center;
}

table.excel tr.row1 td.requerido {
  background-color: #04a398;
  border-top: 1px solid #05ab9f;
  border-left: 1px solid #05ab9f;
  border-right: 1px solid #03968c;
  border-bottom: 1px solid #03968c;
}

table.excel tbody tr.row1 td {
  color: white;
  font-weight: bold;
  background-color: #455a64;
  border-top: 1px solid #4d636e;
  border-left: 1px solid #4d636e;
  border-right: 1px solid #374951;
  border-bottom: 1px solid #374951;
}

table.excel tbody th {
  width: 18px;
}
</style>
