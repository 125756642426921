<template>
  <div>
    <div
      v-if="nominas.filter((n) => n.errores.length > 0).length > 0"
      class="text-caption text-right mx-auto"
    >
      <z-btn text small color="grey" @click="verHojasDescartadas = !verHojasDescartadas">
        ({{ verHojasDescartadas ? 'Ocultar' : 'Ver' }} hojas descartadas)
      </z-btn>
    </div>
    <v-radio-group :value="value" dense hide-details @change="$emit('input', $event)">
      <v-simple-table
        v-if="verHojasDescartadas || nominas.filter((n) => n.errores.length === 0).length > 0"
      >
        <template #default>
          <thead>
            <tr>
              <th>Hoja a importar</th>
              <th class="text-center">Filas con datos</th>
              <th>
                {{ verHojasDescartadas ? 'Error' : '' }}
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(nomina, key) in nominas">
              <tr
                v-if="verHojasDescartadas || nomina.errores.length === 0"
                :key="key"
                :class="{
                  'text--disabled': nomina.errores.length > 0,
                  'font-weight-medium': nomina.name === value,
                }"
              >
                <td>
                  <v-radio
                    :disabled="nomina.errores.length > 0"
                    :label="nomina.name"
                    :value="nomina.name"
                  />
                </td>
                <td class="text-center">
                  {{ $formato.numero(nomina.filas - 1) }}
                </td>
                <td>
                  {{ nomina.errores.join('\n') }}
                </td>
              </tr>
            </template>
          </tbody>
        </template>
      </v-simple-table>
    </v-radio-group>
  </div>
</template>

<script>
import ZBtn from '@/components/ZBtn'
export default {
  components: { ZBtn },
  props: {
    value: String,
    nominas: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    verHojasDescartadas: false,
  }),
  computed: {},
  methods: {},
}
</script>
