<template>
  <v-dialog
    v-if="tramite"
    v-model="dialogDescripcion"
    persistent
    :fullscreen="$vuetify.breakpoint.smAndDown"
    max-width="850"
  >
    <template #activator="{ on, attrs }">
      <z-btn class="ml-1" color="primary" outlined small v-bind="attrs" v-on="on">
        <slot />
      </z-btn>
    </template>
    <v-card>
      <dialog-toolbar @close="dialogDescripcion = false">
        Descripción de nómina de pago
      </dialog-toolbar>
      <v-card-subtitle>
        {{ tramite.nombre }}
      </v-card-subtitle>

      <v-card outlined class="mr-8 ml-auto mt-n16 pa-4" width="180">
        <v-row dense>
          <v-col class="text-caption">
            <cargar-chip-campo :requerido="true" class="mr-2"></cargar-chip-campo>Obligatorio
          </v-col>
        </v-row>
        <v-row>
          <v-col class="text-caption">
            <cargar-chip-campo :requerido="false" class="mr-2"></cargar-chip-campo>No obligatorio
          </v-col>
        </v-row>
      </v-card>

      <v-card-text>
        <v-simple-table>
          <template #default>
            <thead>
              <tr>
                <th>Campo</th>
                <th>Tipo</th>
                <th>Validación</th>
                <th>Descripción</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(columna, campo) in definicion" :key="campo">
                <th class="text-subtitle-2" :class="{ 'primary--text': requerido(columna) }">
                  <cargar-chip-campo :requerido="requerido(columna)">
                    {{ campo }}
                  </cargar-chip-campo>
                </th>
                <td class="text-caption text-center">
                  <small>{{ columna.tipo.match(/\w*$/)[0] }}</small>
                </td>
                <td class="text-caption text-center">
                  <small v-if="!!columna.rules">
                    {{
                      columna.rules
                        .filter((r) => r !== null)
                        .filter((r) => r.match(/^max:|min:/))
                        .join(', ')
                    }}
                  </small>
                </td>
                <td class="text-body-2">
                  {{ columna.descripcion }}
                  <div v-if="columna.ejemplo">
                    <small>Ejemplo: {{ columna.ejemplo }}</small>
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" text @click="dialogDescripcion = false"> Cerrar </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import ZBtn from '@/components/ZBtn'
import CargarChipCampo from '@/admin/views/Nomina/Cargar/CargarChipCampo'
import DialogToolbar from '@/components/DialogToolbar'
export default {
  components: { DialogToolbar, CargarChipCampo, ZBtn },
  props: {
    tramite: {
      type: Object,
      required: true,
    },
    definicion: {
      type: Object,
    },
  },
  data: () => ({
    dialogDescripcion: false,
  }),
  computed: {},
  methods: {
    requerido(columna) {
      return (
        (columna.rules && columna.rules.includes('required')) ||
        (columna.rules && columna.rules.includes('Sem\\Base\\Rules\\Requerido'))
      )
    },
  },
}
</script>
