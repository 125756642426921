<template>
  <div>
    <v-simple-table class="my-8">
      <template #default>
        <thead>
          <tr>
            <th>Columnas del archivo subido</th>
            <th>Campos de {{ tramite.nombre }}</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(header, index) in nomina.headers">
            <tr :key="index">
              <th class="pt-3" style="vertical-align: baseline">
                <cargar-chip-campo
                  :outlined="!mapeoCampos[header]"
                  :style="$vuetify.breakpoint.smAndDown ? 'max-width: 240px' : ''"
                  :title="header"
                  color="grey"
                >
                  <div class="text-truncate">
                    {{ header }}
                  </div>
                </cargar-chip-campo>
              </th>
              <td>
                <v-autocomplete
                  v-model="mapeoCampos[header]"
                  :filter="
                    (item, queryText) => {
                      return item.header ? false : item[0].indexOf(normalizarCampo(queryText)) > -1
                    }
                  "
                  :hint="
                    definicion[mapeoCampos[header]]
                      ? definicion[mapeoCampos[header]].descripcion
                      : ''
                  "
                  :items="camposMapeoDisponibles"
                  class="mt-1"
                  dense
                  flat
                  item-value="0"
                  outlined
                  persistent-hint
                  single-line
                  style="min-width: 200px; max-width: 350px"
                  @input="onSeleccionarMapeo($event, header)"
                >
                  <template #selection="data">
                    <cargar-chip-campo :requerido="requerido(data.item[1])" class="ml-0">
                      {{ data.item[0] }}
                    </cargar-chip-campo>
                  </template>

                  <template #item="data">
                    <v-list-item-content style="max-width: 400px">
                      <v-list-item-title
                        :class="{
                          'grey--text': data.item[1].mapeado,
                          'blue--text': requerido(data.item[1]),
                        }"
                        class="font-weight-medium"
                      >
                        <cargar-chip-campo
                          :outlined="data.item[1].mapeado"
                          :requerido="requerido(data.item[1])"
                          x-small
                        >
                          {{ data.item[0] }}
                        </cargar-chip-campo>
                      </v-list-item-title>
                      <v-list-item-subtitle
                        :class="{ 'grey--text': data.item[1].mapeado }"
                        class="text-caption font-weight-light"
                        v-html="data.item[1].descripcion"
                      />
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </td>
            </tr>
          </template>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import CargarChipCampo from '@/admin/views/Nomina/Cargar/CargarChipCampo'

export default {
  components: {
    CargarChipCampo,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    tramite: {
      type: Object,
      required: true,
    },
    nomina: {
      type: Object,
      required: true,
    },
    definicion: {
      type: Object,
      required: true,
    },
  },
  data: () => ({}),
  computed: {
    mapeoCampos: {
      get: function () {
        return this.value
      },
      set: function (value) {
        this.$emit('input', value)
      },
    },
    columnasMapeadas() {
      return !this.tramite
        ? []
        : Object.entries(this.definicion)
            .filter((c) => Object.values(this.mapeoCampos).includes(c[0]))
            .sort((a, b) => a[0].localeCompare(b[0]))
    },
    columnasNoMapeadas() {
      return !this.tramite
        ? []
        : Object.entries(this.definicion)
            .filter((c) => !Object.values(this.mapeoCampos).includes(c[0]))
            .sort((a, b) => a[0].localeCompare(b[0]))
    },
    columnasNoMapeadasRequeridas() {
      return this.columnasNoMapeadas.filter((c) => this.requerido(c[1]))
    },
    columnasNoMapeadasOpcionales(requerido = true) {
      return this.columnasNoMapeadas.filter((c) => !this.requerido(c[1]))
    },
    camposMapeoDisponibles() {
      if (!this.tramite || !this.definicion) {
        return []
      }

      const obligatorios = this.columnasNoMapeadasRequeridas.map((c) => [
        c[0],
        { ...c[1], mapeado: false },
      ])
      const opcionales = this.columnasNoMapeadasOpcionales.map((c) => [
        c[0],
        { ...c[1], mapeado: false },
      ])
      const mapeados = this.columnasMapeadas.map((c) => [c[0], { ...c[1], mapeado: true }])

      return [
        ...(obligatorios.length ? [{ header: 'CAMPOS OBLIGATORIOS:' }] : []),
        ...obligatorios,
        ...(opcionales.length ? [{ header: 'CAMPOS OPCIONALES:' }] : []),
        ...opcionales,
        ...(mapeados.length ? [{ header: 'CAMPOS YA MAPEADOS:' }] : []),
        ...mapeados,
      ]
    },
  },

  methods: {
    /**
     * Verifica que el campo mapeado no esté mapeado en otra columna
     */
    onSeleccionarMapeo(campoNew, headerNew) {
      const newMapeo = this.mapeoCampos
      Object.entries(this.mapeoCampos).forEach(([campo, header]) => {
        if (headerNew !== campo && header === campoNew) {
          newMapeo[campo] = null
        }
      })
      this.mapeoCampos = newMapeo
    },
    normalizarCampo(value) {
      return this.$formato.normalizar(value).trim().replaceAll(/\W+/g, '_')
    },
    requerido(columna) {
      return columna.rules && columna.rules.includes('required')
    },
  },
}
</script>
