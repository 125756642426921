<template>
  <div>
    <v-stepper v-model="paso" vertical>
      <!--
      .
      .
      .
      .
      .
      .
      .
      #############################################################################################

                                          PASO 1

      #############################################################################################

      -->

      <v-stepper-step :complete="paso > 1" step="1">
        Trámite
        <small v-if="tramite">{{ tramite.nombre }}</small>
      </v-stepper-step>

      <v-stepper-content step="1">
        <v-row class="mt-2 ml-1 mr-1">
          <v-col sm="9" cols="12">
            <input-tramite
              v-model="tramiteSlug"
              :filter="(t) => !['pagos-genericos', 'multas'].includes(t.slug)"
            />
          </v-col>
          <v-col v-if="cuentasPagadas" sm="3" cols="12">
            <z-input
              v-model="tipoPagoExterno"
              tipo="select"
              :items="payloadListatipoPagoExterno"
              label="Tipo de pago"
              name="tipo_pago_externo"
              clearable
            />
          </v-col>
        </v-row>

        <div style="min-height: 150px" class="mb-4">
          <v-expand-transition>
            <div v-if="!!tramite && !!tramite.definicion">
              <div class="text-h6">Ejemplo de nómina de pago</div>
              <div class="text--secondary text-body-2">
                Puedes subir una nómina en formato Excel o CSV. La primera fila debe tener los
                títulos aquí indicados, los campos en color verde son obligatorios, el orden de las
                columnas no afecta la carga. En la fila 2 se incluye un ejemplo de datos.
              </div>

              <cargar-simula-excel
                :tramite="tramite"
                :definicion="definicionColumnasNominaDePago"
                class="my-2"
              />

              <z-btn
                :disabled="!tramiteSlug"
                color="primary"
                outlined
                small
                @click="descargarEjemplo"
              >
                <v-icon left> mdi-download </v-icon>
                Descargar ejemplo
              </z-btn>

              <cargar-dialog-descripcion-nomina
                :tramite="tramite"
                :definicion="definicionColumnasNominaDePago"
              >
                Ver descripción
              </cargar-dialog-descripcion-nomina>
            </div>
          </v-expand-transition>
        </div>

        <z-btn
          :disabled="!tramite || (!tipoPagoExterno && cuentasPagadas)"
          class="my-4"
          color="primary"
          @click="paso++"
        >
          Continuar
        </z-btn>
      </v-stepper-content>

      <!--
      .
      .
      .
      .
      .
      .
      .
      #############################################################################################

                                          PASO 2

      #############################################################################################

      -->

      <v-stepper-step :complete="paso > 2" step="2">
        Selección de archivo
        <small v-if="paso >= 2 && file && file.name">
          {{ file.name }}
          <span v-if="hoja && hoja !== 'Sheet1'"> • {{ hoja }} </span>
          <span v-if="nomina">
            • {{ $formato.textoSingularPlural(nomina.filas, 'fila', 'filas en total') }}
          </span>
        </small>
      </v-stepper-step>

      <v-stepper-content step="2">
        <div v-if="paso >= 2">
          <div class="d-inline-flex align-center" style="width: 100%; height: 85px">
            <v-file-input
              v-show="!loading"
              v-model="file"
              :error-messages="
                nominas.length > 0 && nominas.filter((n) => n.errores.length === 0).length === 0
                  ? 'Error: no hay hojas válidas para importar.'
                  : null
              "
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,text/csv,application/vnd.oasis.opendocument.spreadsheet"
              prepend-icon="mdi-paperclip"
              class="mt-4"
              label="Seleccionar archivo"
              outlined
              show-size
              hint="Tamaño máximo: 8 MB"
              persistent-hint
              @change="analizarFile"
            />

            <div v-if="!!loading" class="flex">
              <v-progress-circular :size="20" color="primary" indeterminate width="2" />
              <span class="text-caption text--secondary ml-1"> Analizando archivo... </span>
            </div>
          </div>

          <cargar-seleccionar-hoja
            v-if="nominas.length > 0 && !loading"
            v-model="hoja"
            :nominas="nominas"
          />

          <div class="mt-4">
            <z-btn
              :disabled="
                nominas.length === 0 || !!loading || !hoja || !file || file.size > fileMaxSize
              "
              color="primary"
              @click="paso++"
            >
              Continuar
            </z-btn>
            <z-btn color="primary" text @click="paso--"> Anterior </z-btn>
          </div>
        </div>
      </v-stepper-content>

      <!--
      .
      .
      .
      .
      .
      .
      .
      #############################################################################################

                                          PASO 3

      #############################################################################################

      -->

      <v-stepper-step :complete="paso > 3" step="3"> Mapeo de datos </v-stepper-step>

      <v-stepper-content step="3">
        <div v-if="paso >= 3">
          <div v-if="nomina">
            <v-alert v-if="columnasNoMapeadasRequeridas.length === 0" outlined type="success">
              Los campos obligatorios para realizar la importación están mapeados correctamente.
              <div v-if="columnasNoMapeadasRequeridas.length === 0">
                <v-switch v-model="verMapeo" color="success" label="Ver mapeo de campos." />
              </div>
            </v-alert>

            <v-card v-if="columnasNoMapeadasRequeridas.length > 0" class="my-4" outlined>
              <v-card-title> Hay campos obligatorios por mapear </v-card-title>
              <v-card-subtitle>
                Campos obligatorios que faltan:
                <cargar-chip-campo
                  v-for="campo in columnasNoMapeadasRequeridas"
                  :key="campo[0]"
                  class="mr-1"
                  requerido
                  x-small
                >
                  {{ campo[0] }}
                </cargar-chip-campo>
              </v-card-subtitle>
              <v-card-text>
                Los nombres de columnas de la nómina que intentas subir no coinciden con los campos
                requeridos para {{ tramite.nombre }}. Tendrás que indicar la correspondencia entre
                las columnas de la nómina que subiste (izquierda) y las que el sistema espera
                encontrar (derecha).
              </v-card-text>
            </v-card>

            <v-expand-transition>
              <cargar-tabla-mapeo
                v-show="verMapeo || columnasNoMapeadasRequeridas.length > 0"
                v-model="mapeo"
                :tramite="tramite"
                :nomina="nomina"
                :definicion="definicionColumnasNominaDePago"
              />
            </v-expand-transition>
          </div>

          <v-card v-if="columnasNoMapeadasRequeridas.length > 0" class="my-8" outlined>
            <v-card-title> Campos pendientes de ser mapeados </v-card-title>
            <v-card-subtitle>
              Para avanzar al próximo paso, te falta mapear al menos los campos obligatorios que
              requiere el sistema:
            </v-card-subtitle>
            <v-card-text>
              <cargar-chip-campo
                v-for="campo in columnasNoMapeadasRequeridas"
                :key="campo[0]"
                class="mr-1 mt-1"
                requerido
              >
                {{ campo[0] }}
              </cargar-chip-campo>
            </v-card-text>
          </v-card>

          <z-btn
            :disabled="columnasNoMapeadasRequeridas.length > 0"
            class="my-2"
            color="primary"
            @click="paso++"
          >
            Continuar
          </z-btn>
          <z-btn class="my-2" color="primary" text @click="paso--"> Anterior </z-btn>
        </div>
      </v-stepper-content>

      <!--
      .
      .
      .
      .
      .
      .
      .
      #############################################################################################

                                          PASO 4

      #############################################################################################

      -->

      <v-stepper-step step="4"> Cargar nómina </v-stepper-step>

      <v-stepper-content step="4">
        <div v-if="paso >= 4">
          <v-card v-if="!!mensajesErrorCarga" outlined>
            <v-card-title>No se pudo cargar la nómina</v-card-title>
            <v-card-subtitle> {{ mensajesErrorCarga._message }}</v-card-subtitle>
            <v-card-text v-if="!!mensajesErrorCarga.archivo">
              <cargar-nomina-errores :errores="mensajesErrorCarga.archivo.filas" />
            </v-card-text>
            <v-card-text v-else-if="!mensajesErrorCarga._message">
              {{ mensajesErrorCarga }}
            </v-card-text>

            <v-card-actions class="mx-2">
              <v-btn
                v-if="false && !!mensajesErrorCarga.archivo"
                outlined
                color="primary"
                class="my-4"
                @click="reiniciarPasos"
              >
                <v-icon left> mdi-download </v-icon>
                Descargar detalle de errores
              </v-btn>
              <v-btn outlined color="primary" class="my-4" @click="reiniciarPasos">
                <v-icon left> mdi-restart </v-icon>
                Volver a comenzar
              </v-btn>
            </v-card-actions>
          </v-card>

          <div v-if="!mensajesErrorCarga">
            <v-alert v-if="fechaPagoEstaMapeada && cuentasPagadas" outlined type="info">
              Esta nómina incluye el mapeo de fecha de pago. Será utilizada para marcar los pagos
              presenciales realizados fuera de SEM.
            </v-alert>

            <v-row v-if="!cuentasPagadas">
              <v-col>
                <v-checkbox
                  v-model="upsert"
                  dense
                  label="Sobreescribir eventuales datos existentes."
                  hint="Si la nómina que vas a cargar contiene datos ya informados en la plataforma, podrás sobreescribir aquellos datos marcando acá."
                  persistent-hint
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-checkbox
                  v-model="sincrona"
                  :disabled="forzarAsincrona"
                  dense
                  label="Cargar nómina inmediatamente."
                  hint="Las nóminas de hasta 5.000 filas se pueden procesar de inmediato y ver los errores en esta misma página."
                  persistent-hint
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="8" md="6">
                <z-input
                  v-model.trim="nombreNomina"
                  :dense="false"
                  maxlength="100"
                  label="Nombre de la nómina"
                  :rules="[
                    (value) => !!value || 'Requerido.',
                    (value) => value.length >= 5 || 'Ingresa al menos 5 caracteres.',
                  ]"
                />
              </v-col>
            </v-row>

            <div>
              <z-btn
                class="my-2"
                color="primary"
                :disabled="nombreNomina.length < 5"
                :loading="!!loading"
                @click="subirNomina"
              >
                <v-icon left> mdi-cloud-upload-outline </v-icon>
                Subir nómina al sistema
              </z-btn>
              <z-btn class="my-2" color="primary" text @click="paso--"> Anterior </z-btn>
            </div>
          </div>
        </div>
      </v-stepper-content>
      <!--
      .
      .
      .
      .
      .
      .
      .
      #############################################################################################

                                          PASO 5

      #############################################################################################

      -->

      <v-stepper-step step="5"> Confirmación </v-stepper-step>

      <v-stepper-content step="5">
        <div v-if="paso >= 5">
          <div>
            <v-alert type="success">
              <span v-if="!sincrona">
                El sistema se encuentra procesando la nómina, en los próximos minutos recibirá un
                mensaje al correo
                <span class="font-weight-medium">{{ $auth.user().email }}</span> informando el
                resultado de la carga. Sin embargo, puede continuar usando el portal normalmente.
              </span>
              <span v-else-if="nominaCargada.cantidad === 1">
                Se cargó un registro de <span class="font-weight-medium">{{ tramite.nombre }}</span
                >.
              </span>
              <span v-else>
                Se cargaron {{ $formato.numero(nominaCargada.cantidad) }} registros de
                <span class="font-weight-medium">{{ tramite.nombre }}</span
                >.
              </span>

              <br />
              <v-btn outlined class="my-4" to="/nominas"> Terminar </v-btn>
            </v-alert>
          </div>
        </div>
      </v-stepper-content>
    </v-stepper>
  </div>
</template>

<script>
import * as XLSX from 'xlsx'
import ExcelJS from 'exceljs/dist/es5/exceljs.browser.js'
import { saveAs } from 'file-saver'
import ZBtn from '@/components/ZBtn'
import CargarSimulaExcel from './Cargar/CargarSimulaExcel'
import CargarDialogDescripcionNomina from './Cargar/CargarDialogDescripcionNomina'
import CargarSeleccionarHoja from '@/admin/views/Nomina/Cargar/CargarSeleccionarHoja'
import CargarChipCampo from './Cargar/CargarChipCampo'
import CargarTablaMapeo from './Cargar/CargarTablaMapeo'
import { getError } from '@/utils/errors'
import InputTramite from '@/components/Input/InputTramite'
import ZInput from '@/components/Input/ZInput'
import CargarNominaErrores from '@/admin/views/Nomina/CargarNominaErrores'

export default {
  components: {
    CargarNominaErrores,
    ZInput,
    InputTramite,
    CargarTablaMapeo,
    CargarChipCampo,
    CargarSeleccionarHoja,
    CargarDialogDescripcionNomina,
    CargarSimulaExcel,
    ZBtn,
  },
  data: () => ({
    paso: 1,
    tramiteSlug: '',
    file: null,
    fileMaxSize: 8 * 1024 * 1024,
    nominas: [],
    hoja: '',
    nombreNomina: '',
    loading: 0,
    verMapeo: false,
    upsert: false,
    sincrona: false,
    forzarAsincrona: false,
    mapeo: {},
    nominaCargada: {},
    mensajesErrorCarga: '',
    tipoPagoExterno: '',
    filtrarNominaDePago: false,
    fecha_pago: [
      'fecha_pago',
      {
        tipo: 'Sem\\Tramites\\Tramites\\TiposCampo\\FechaPago',
        nombre: 'Fecha de pago',
        nominaDePago: true,
        rules: ['required'],
        ejemplo: '26-09-2021 14:34',
        descripcion: 'Campo para indicar que la cuenta ha sido pagada en sistemas externos.',
      },
    ],
  }),
  computed: {
    tramite() {
      return this.$payload.get('tramites').find((p) => p.slug === this.tramiteSlug)
    },
    nomina() {
      return this.nominas.find((v) => v.name === this.hoja)
    },
    definicionColumnasNominaDePago() {
      const definicionColumnas = { ...this.tramite.definicion.columnas }

      // Si viene filtrarNominaDePago se filtraran las columnas que tengan el campo nominaDePago en true
      if (this.$route.query.cuentasPagadas === 'true') {
        var definicionNominaDePago = Object.entries(definicionColumnas).filter(
          (c) => c[1].nominaDePago === true
        )

        definicionNominaDePago.push(this.fecha_pago)

        return Object.fromEntries(definicionNominaDePago)
      }

      if (!this.filtrarNominaDePago) {
        delete definicionColumnas.fecha_pago
      }

      return definicionColumnas
    },
    columnasNoMapeadas() {
      return !this.tramite
        ? []
        : Object.entries(this.definicionColumnasNominaDePago)
            .filter((c) => !Object.values(this.mapeo).includes(c[0]))
            .sort((a, b) => a[0].localeCompare(b[0]))
    },
    columnasNoMapeadasRequeridas() {
      return this.columnasNoMapeadas.filter((c) => this.requerido(c[1]))
    },
    columnasNoMapeadasOpcionales(requerido = true) {
      return this.columnasNoMapeadas.filter((c) => !this.requerido(c[1]))
    },
    fechaPagoEstaMapeada() {
      return Object.values(this.mapeo).includes('fecha_pago')
    },
    cuentasPagadas() {
      return this.$route.query.cuentasPagadas === 'true'
    },
  },
  watch: {
    /**
     * Cambio de hoja seleccionada
     */
    hoja(newValue, oldValue) {
      if (this.nomina) {
        const mapeo = {}
        this.nomina.headers.forEach((header) => {
          if (this.definicionColumnasNominaDePago[header]) {
            mapeo[header] = header
          }
        })

        this.mapeo = mapeo
      }
    },
    forzarAsincrona(forzar) {
      if (forzar) {
        this.sincrona = false
      }
    },
    paso(nuevoPaso, pasoAnterior) {
      if (nuevoPaso === 3) {
        this.sincrona = false
        this.forzarAsincrona = this.nomina.filas > 5000
      }
    },
  },
  methods: {
    requerido(columna) {
      return columna.rules && columna.rules.includes('required')
    },
    async descargarEjemplo() {
      const vue = this
      const wb = new ExcelJS.Workbook()
      const worksheet = wb.addWorksheet('Hoja 1', {
        // properties: { tabColor: { argb: 'FFC00000' } },
        views: [{ state: 'frozen', xSplit: 0, ySplit: 1 }],
      })

      /**
       * Definición de columnas
       */
      worksheet.columns = Object.entries(this.definicionColumnasNominaDePago).map(
        ([key, value]) => ({
          header: key,
          key: key,
          width: Math.min(30, Math.max(10, key.length)),
        })
      )
      // worksheet.getCell('A2').dataValidation = {
      //   allowBlank: true,
      //   type: 'list',
      //   formulae: ['"One,Two,Three,Four"'],
      // }

      /**
       * Fila de cabecera
       */
      const columnas = Object.values(this.definicionColumnasNominaDePago)
      worksheet.getRow(1).eachCell((cell, colNumber) => {
        cell.note = columnas[colNumber - 1]?.descripcion
        cell.style = {
          font: {
            bold: true,
            color: {
              argb: 'FFFFFFFF',
            },
          },
          fill: {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: vue.requerido(columnas[colNumber - 1]) ? 'FF04A398' : 'FF455A64' },
          },
        }
      })

      /**
       * Fila con datos de ejemplo
       */
      worksheet.addRow(Object.values(this.definicionColumnasNominaDePago).map((c) => c.ejemplo))

      /*
// Ejemplo de celda con error
worksheet.getCell('A2').style = {
font: {
color: {
argb: 'FF9C0006',
},
},
border: {},
fill: {
type: 'pattern',
pattern: 'solid',
fgColor: {
argb: 'FFFFC7CE',
},
},
}
*/

      /**
       * Envía archivo
       */
      saveAs(new Blob([await wb.xlsx.writeBuffer()]), this.tramiteSlug + '.xlsx')
    },

    normalizarCampo(value) {
      return this.$formato.normalizar(value).trim().replaceAll(/\W+/g, '_')
    },

    analizarFile(file) {
      this.loading = 1
      // console.clear()
      this.hoja = null
      this.nominas = []
      if (!file) {
        this.loading = 0
        return
      }
      if (file.size > this.fileMaxSize) {
        alert(
          'El archivo es demasiado grande.\nConsidera eliminar hojas, columnas innecesarias o separarlo en varios archivos.'
        )
      }
      // const vue = this
      const reader = new FileReader()
      reader.onload = (e) => {
        const workbook = XLSX.read(e.target.result, {
          type: 'binary',
          cellHTML: false,
        })
        workbook.SheetNames.forEach((sheetName) => {
          const worksheet = workbook.Sheets[sheetName]
          let data = XLSX.utils.sheet_to_json(worksheet, { raw: true, header: 1, defval: '' })
          // Quita líneas en blanco
          data = data.filter((r) => r.join('').trim() !== '')
          const filas = data.length
          const headers = (data[0] ?? []).map((v) => this.normalizarCampo(v))
          const nomina = {
            name: sheetName,
            filas,
            headers,
            errores: [],
          }
          // console.log(nomina)
          if (filas <= 1) {
            nomina.errores.push('No hay filas con datos.')
          } else if (
            headers.length <
            Object.values(this.definicionColumnasNominaDePago).filter((c) => this.requerido(c))
              .length
          ) {
            nomina.errores.push('No hay suficientes columnas.')
          }
          this.nominas.push(nomina)
          if (nomina.errores.length === 0) {
            this.hoja ??= sheetName
          }
        })
        this.loading = 0
      }
      reader.readAsBinaryString(file)
    },
    reiniciarPasos() {
      this.paso = 1
      this.loading = 0
      this.nominaCargada = {}
      this.file = null
      this.hoja = ''
      this.nombreNomina = ''
      this.nominas = []
      this.mensajesErrorCarga = ''
    },
    subirNomina() {
      const headers = { 'Content-Type': 'multipart/form-data' }
      const formData = new FormData()
      formData.append('fecha_request', this.$dayjs().toJSON())
      formData.append('archivo', this.file)
      formData.append('nombre', this.nombreNomina)
      formData.append('hoja', this.hoja)
      formData.append('tipo_pago_externo', this.tipoPagoExterno)

      if (this.cuentasPagadas === true) {
        this.upsert = true
      }

      console.log(this.upsert)

      for (const [key, value] of Object.entries(this.mapeo)) {
        formData.append(`mapeo[${key}]`, value)
      }
      formData.append('upsert', this.upsert)
      formData.append('sincrona', this.sincrona)
      const startTime = performance.now()
      this.loading = 1
      this.$axios
        .post(`tramites/${this.tramiteSlug}/nominas`, formData, {
          headers,
          timeout: this.sincrona ? (180 + 10) * 1000 : 30 * 1000,
        })
        .then((response) => {
          this.paso++
          this.nominaCargada = response.data.data
        })
        .catch((error) => {
          console.warn(error)
          let mensajeError = getError(error)
          const status = error?.response?.status
          const segundos = (performance.now() - startTime) / 1000

          console.log(status)
          console.log(mensajeError)
          console.log(segundos)

          if (status === 413) {
            // Payload Too Large
            mensajeError =
              'El archivo es demasiado grande. Para una importación más eficiente, considera separar la nómina en archivos que no superen 4 MB ni 10.000 filas.'
          } else if (status === 422) {
            // Sin cambios
          } else if (status === 504 || ((!status || status >= 500) && segundos >= 30)) {
            // Gateway Timeout
            mensajeError =
              'La importación tardó demasiado. Para una importación más eficiente, considera separar la nómina en archivos que no superen 4 MB ni 10.000 filas.'
          }
          this.mensajesErrorCarga = mensajeError
        })
        .finally(() => {
          this.loading = 0
        })
    },
  },
}
</script>
