var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-simple-table',{staticClass:"my-8",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',[_vm._v("Columnas del archivo subido")]),_c('th',[_vm._v("Campos de "+_vm._s(_vm.tramite.nombre))])])]),_c('tbody',[_vm._l((_vm.nomina.headers),function(header,index){return [_c('tr',{key:index},[_c('th',{staticClass:"pt-3",staticStyle:{"vertical-align":"baseline"}},[_c('cargar-chip-campo',{style:(_vm.$vuetify.breakpoint.smAndDown ? 'max-width: 240px' : ''),attrs:{"outlined":!_vm.mapeoCampos[header],"title":header,"color":"grey"}},[_c('div',{staticClass:"text-truncate"},[_vm._v(" "+_vm._s(header)+" ")])])],1),_c('td',[_c('v-autocomplete',{staticClass:"mt-1",staticStyle:{"min-width":"200px","max-width":"350px"},attrs:{"filter":(item, queryText) => {
                    return item.header ? false : item[0].indexOf(_vm.normalizarCampo(queryText)) > -1
                  },"hint":_vm.definicion[_vm.mapeoCampos[header]]
                    ? _vm.definicion[_vm.mapeoCampos[header]].descripcion
                    : '',"items":_vm.camposMapeoDisponibles,"dense":"","flat":"","item-value":"0","outlined":"","persistent-hint":"","single-line":""},on:{"input":function($event){return _vm.onSeleccionarMapeo($event, header)}},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('cargar-chip-campo',{staticClass:"ml-0",attrs:{"requerido":_vm.requerido(data.item[1])}},[_vm._v(" "+_vm._s(data.item[0])+" ")])]}},{key:"item",fn:function(data){return [_c('v-list-item-content',{staticStyle:{"max-width":"400px"}},[_c('v-list-item-title',{staticClass:"font-weight-medium",class:{
                        'grey--text': data.item[1].mapeado,
                        'blue--text': _vm.requerido(data.item[1]),
                      }},[_c('cargar-chip-campo',{attrs:{"outlined":data.item[1].mapeado,"requerido":_vm.requerido(data.item[1]),"x-small":""}},[_vm._v(" "+_vm._s(data.item[0])+" ")])],1),_c('v-list-item-subtitle',{staticClass:"text-caption font-weight-light",class:{ 'grey--text': data.item[1].mapeado },domProps:{"innerHTML":_vm._s(data.item[1].descripcion)}})],1)]}}],null,true),model:{value:(_vm.mapeoCampos[header]),callback:function ($$v) {_vm.$set(_vm.mapeoCampos, header, $$v)},expression:"mapeoCampos[header]"}})],1)])]})],2)]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }